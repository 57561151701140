import service from './request'
import {
  Message,
  Loading
} from 'element-ui'

//接口前缀
//upms
const UPMS_PREFIX = '/valle/upms';
//ptms
const PTMS_PREFIX = '/valle/ptms';

let NOPREFIX = ''
if (process.env.NODE_ENV === 'development') {
  NOPREFIX = '/api_target'
} else {
  NOPREFIX = '';
}

export{
  NOPREFIX
}
/* ----------------- 登录模块start ---------------------- */

// 用户登录
const login = data => {
  const url = `${NOPREFIX}/valle/auth/oauth/token`;
  return new Promise(function (resolve, reject) {
    //原生构造ajax请求
    let loginXhr = new XMLHttpRequest();
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    loginXhr.onreadystatechange = function () {
      if (this.readyState == 4) {
        if (this.status == 200) {
          resolve(this.response)
        } else {
          reject(JSON.parse(this.responseText));
        }
      }
    }
    loginXhr.open("post", url);
    loginXhr.send(formData);
  }).then((res) => {
      return {
        code: 0,
        data: JSON.parse(res)
      }
    },
    (err) => {
      //console.log(err)
      return err
    });
}

// 用户登出
const loginOut = () => {
  const url = `${NOPREFIX}/valle/auth/token/logout`;
  return new Promise(function (resolve, reject) {
    //原生构造ajax请求
    let logoutXhr = new XMLHttpRequest();
    logoutXhr.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        resolve(this.response)
      }
    }
    logoutXhr.open("delete", url);
    logoutXhr.setRequestHeader("Authorization", `bearer  ${localStorage.getItem("token")}`);
    logoutXhr.send();
  }).then((res) => {
      return {
        code: 0,
        data: JSON.parse(res)
      }
    },
    (err) => {
      console.log(err);
      return {
        code: 1,
        data: '退出登录失败'
      }
    });
}

export {
  login,
  loginOut
}

/* ----------------- 登录模块end ---------------------- */

/* ----------------- 注册模块start ---------------------- */
//注册模块接口前缀
const REG_PREFIX = '/api/register';

// 生成验证码
const getRegisterCode = data => {
  return service({
    url: `${UPMS_PREFIX}${REG_PREFIX}/code`,
    method: 'get',
    data,
    noToken:true
  })
}

// 用户注册
const registerUser = data => {
  return service({
    url: `${UPMS_PREFIX}${REG_PREFIX}/user`,
    method: 'post',
    data,
    noToken:true
  })
}
export {
  getRegisterCode,
  registerUser
}
/* ----------------- 注册模块end ---------------------- */

/* ----------------- 用户模块start ---------------------- */

//用户模块接口前缀
const USER_PREFIX = '/users';

// 修改密码
const updatePassword = data => {
  return service({
    url: `${UPMS_PREFIX}${USER_PREFIX}/password`,
    method: 'put',
    data
  })
}

// 修改用户状态
const updateUserStatus = data => {
  return service({
    url: `${UPMS_PREFIX}${USER_PREFIX}/status`,
    method: 'put',
    header: 'application/x-www-form-urlencoded',
    data
  })
}

// 条件查询用户列表
const getUserListByField = data => {
  return service({
    url: `${UPMS_PREFIX}${USER_PREFIX}/page`,
    method: 'get',
    data
  })
}

// 删除用户
const deleteUser = data => {
  return service({
    url: `${UPMS_PREFIX}${USER_PREFIX}/${data}`,
    method: 'delete',
  })
}

// 新增用户
const insertUser = data => {
  return service({
    url: `${UPMS_PREFIX}${USER_PREFIX}`,
    method: 'post',
    data
  })
}

// 编辑用户
const updateUser = data => {
  return service({
    url: `${UPMS_PREFIX}${USER_PREFIX}`,
    method: 'put',
    data
  })
}

//获取当前用户全部信息
const getUserPersonal = data => {
  return service({
    url: `${UPMS_PREFIX}${USER_PREFIX}/current`,
    method: 'get',
  })
}

// 编辑个人用户信息
const updateUserPersonal = data => {
  return service({
    url: `${UPMS_PREFIX}${USER_PREFIX}/edit`,
    method: 'put',
    data
  })
}

// 获取单个用户信息
const getUserInfoById = data => {
  return service({
    url: `${UPMS_PREFIX}${USER_PREFIX}/${data}`,
    method: 'get',
  })
}

// 重置密码
const resetPsw = data => {
  return service({
    url: `${UPMS_PREFIX}${USER_PREFIX}/reset/password/user/${data}`,
    method: 'put',
  })
}

export {
  updatePassword,
  updateUserStatus,
  getUserListByField,
  deleteUser,
  insertUser,
  updateUser,
  getUserPersonal,
  updateUserPersonal,
  getUserInfoById,
  resetPsw
}

/* ----------------- 用户模块end ---------------------- */

/* ----------------- 菜单模块start ---------------------- */

//菜单模块接口前缀
const MENU_PREFIX = '/menus'

//返回当前用户的树形菜单集合
const getUserMenus = data => {
  return service({
    url: `${UPMS_PREFIX}${MENU_PREFIX}?parentId=${data}`,
    method: 'get',
  })
}

//新增菜单权限信息
const insertMenu = data => {
  return service({
    url: `${UPMS_PREFIX}${MENU_PREFIX}`,
    method: 'post',
    data
  })
}

//修改菜单权限信息
const updateMenu = data => {
  return service({
    url: `${UPMS_PREFIX}${MENU_PREFIX}`,
    method: 'put',
    data
  })
}

// 查询用户菜单树
const getMenus = data => {
  return service({
    url: `${UPMS_PREFIX}${MENU_PREFIX}/tree`,
    method: 'get',
    data
  })
}

// 返回角色的菜单集合 参数roleId
const getRoleMenusTree = data => {
  return service({
    url: `${UPMS_PREFIX}${MENU_PREFIX}/tree/${data}`,
    method: 'get',
  })
}

// 通过id查询菜单权限信息
const getMenuById = data => {
  return service({
    url: `${UPMS_PREFIX}${MENU_PREFIX}/${data}`,
    method: 'get',
  })
}

// 通过id查询菜单权限信息
const deleteMenuById = data => {
  return service({
    url: `${UPMS_PREFIX}${MENU_PREFIX}/${data}`,
    method: 'delete',
  })
}

// 修改菜单状态
const updateMenuStatus = data => {
  return service({
    url: `${UPMS_PREFIX}${MENU_PREFIX}/status`,
    method: 'put',
    header: 'application/x-www-form-urlencoded',
    data
  })
}

export {
  getUserMenus,
  insertMenu,
  getMenus,
  getRoleMenusTree,
  getMenuById,
  updateMenu,
  deleteMenuById,
  updateMenuStatus
}

/* ----------------- 菜单模块end ---------------------- */

/* ----------------- 企业模块start ---------------------- */

//企业模块接口前缀
const DEPT_PREFIX = '/depts'

//获取企业（部门）列表--返回树形菜单集合
const deptTree = () => {
  return service({
    url: `${UPMS_PREFIX}${DEPT_PREFIX}/tree`,
    method: 'get',
  })
}

//新增企业（部门）
const insertDept = data => {
  return service({
    url: `${UPMS_PREFIX}${DEPT_PREFIX}`,
    method: 'post',
    data
  })
}

//修改更新企业（部门）
const updateDept = data => {
  return service({
    url: `${UPMS_PREFIX}${DEPT_PREFIX}`,
    method: 'put',
    data
  })
}

//删除企业（部门） 参数 id
const deleteDept = data => {
  return service({
    url: `${UPMS_PREFIX}${DEPT_PREFIX}/${data}`,
    method: 'delete',
  })
}

//通过id获取企业（部门）信息 参数id
const getDeptById = data => {
  return service({
    url: `${UPMS_PREFIX}${DEPT_PREFIX}/${data}`,
    method: 'get',
  })
}

//查询子级列表 参数 deptId
const getDeptDescendants = data => {
  return service({
    url: `${UPMS_PREFIX}${DEPT_PREFIX}/descendant/list/${data}`,
    method: 'get',
  })
}

//修改企业部门状态
const updateDeptStatus = data => {
  return service({
    url: `${UPMS_PREFIX}${DEPT_PREFIX}/status`,
    method: 'put',
    header: 'application/x-www-form-urlencoded',
    data
  })
}

export {
  deptTree,
  insertDept,
  updateDept,
  deleteDept,
  getDeptById,
  getDeptDescendants,
  updateDeptStatus
}

/* ----------------- 企业模块end ---------------------- */

/* ----------------- 角色模块start ---------------------- */

//角色模块接口前缀
const ROLE_PREFIX = '/roles'

// 查询角色列表
const queryRoleList = data => {
  return service({
    url: `${UPMS_PREFIX}${ROLE_PREFIX}/page`,
    method: 'get',
    data
  })
}

// 新增角色
const insertRole = data => {
  return service({
    url: `${UPMS_PREFIX}${ROLE_PREFIX}`,
    method: 'post',
    data
  })
}

// 修改角色
const updateRole = data => {
  return service({
    url: `${UPMS_PREFIX}${ROLE_PREFIX}`,
    method: 'put',
    data
  })
}


// 修改角色状态
const updateRoleStatus = data => {
  return service({
    url: `${UPMS_PREFIX}${ROLE_PREFIX}/status`,
    method: 'put',
    header: 'application/x-www-form-urlencoded',
    data
  })
}

// 删除角色
const deleteRole = data => {
  return service({
    url: `${UPMS_PREFIX}${ROLE_PREFIX}/${data}`,
    method: 'delete',
  })
}

// 通过id查询角色信息
const queryRoleById = data => {
  return service({
    url: `${UPMS_PREFIX}${ROLE_PREFIX}/${data}`,
    method: 'get',
  })
}
// 获取角色名列表
const getRoleLabelList = data => {
  return service({
    url: `${UPMS_PREFIX}${ROLE_PREFIX}/list`,
    method: 'get',
    data
  })
}

export {
  queryRoleList,
  insertRole,
  updateRole,
  deleteRole,
  queryRoleById,
  getRoleLabelList,
  updateRoleStatus

}

/* ----------------- 角色模块end ---------------------- */

/* ----------------- 终端模块start ---------------------- */

//终端模块接口前缀
const CLIENT_PREFIX = '/clients'

// 查询终端列表
const getDeviceList = data => {
  return service({
    url: `${UPMS_PREFIX}${CLIENT_PREFIX}/page`,
    method: 'get',
    data
  })
}

// 新增终端
const insertDevice = data => {
  return service({
    url: `${UPMS_PREFIX}${CLIENT_PREFIX}`,
    method: 'post',
    data
  })
}

// 删除终端
const deleteDevice = data => {
  return service({
    url: `${UPMS_PREFIX}${CLIENT_PREFIX}/${data}`,
    method: 'delete',
  })
}

// 修改终端
const updateDevice = data => {
  return service({
    url: `${UPMS_PREFIX}${CLIENT_PREFIX}`,
    method: 'put',
    data
  })
}

// 根据id搜索终端
const queryClientsById = data => {
  return service({
    url: `${UPMS_PREFIX}${CLIENT_PREFIX}/${data}`,
    method: 'get',
  })
}

//修改终端状态
const updateDeviceStatus = data => {
  return service({
    url: `${UPMS_PREFIX}${CLIENT_PREFIX}/status`,
    method: 'put',
    header: 'application/x-www-form-urlencoded',
    data
  })
}

export {
  getDeviceList,
  insertDevice,
  deleteDevice,
  updateDevice,
  queryClientsById,
  updateDeviceStatus
}

/* ----------------- 终端模块end ---------------------- */

/* ----------------- 字典管理模块start ---------------------- */

//字典管理模块接口前缀DICT_PREFIX='/dicts'
const DICT_PREFIX = '/dicts'

// 分页查询字典信息
const getDictsPage = data => {
  return service({
    url: `${UPMS_PREFIX}${DICT_PREFIX}/page`,
    method: 'get',
    data
  })
}

//新增字典
const insertDicts = data => {
  return service({
    url: `${UPMS_PREFIX}${DICT_PREFIX}`,
    method: 'post',
    data
  })
}

//修改字典
const updateDicts = data => {
  return service({
    url: `${UPMS_PREFIX}${DICT_PREFIX}`,
    method: 'put',
    data
  })
}

//删除字典 参数字典id
const deleteDicts = data => {
  return service({
    url: `${UPMS_PREFIX}${DICT_PREFIX}/${data}`,
    method: 'delete',
  })
}

//根据字典id获取字典信息
const getDictsById = data => {
  return service({
    url: `${UPMS_PREFIX}${DICT_PREFIX}/${data}`,
    method: 'get',
  })
}

//修改字典状态
const updateDictsStatus = data => {
  return service({
    url: `${UPMS_PREFIX}${DICT_PREFIX}/status`,
    method: 'put',
    header: 'application/x-www-form-urlencoded',
    data
  })
}

//分页查询字典项
const getDictsItemsPage = data => {
  return service({
    url: `${UPMS_PREFIX}${DICT_PREFIX}/items/page`,
    method: 'get',
    data
  })
}

//新增字典项
const insertDictsItems = data => {
  return service({
    url: `${UPMS_PREFIX}${DICT_PREFIX}/items`,
    method: 'post',
    data
  })
}

//修改字典项
const updateDictsItems = data => {
  return service({
    url: `${UPMS_PREFIX}${DICT_PREFIX}/items`,
    method: 'put',
    data
  })
}

//删除字典项
const deleteDictsItems = data => {
  return service({
    url: `${UPMS_PREFIX}${DICT_PREFIX}/items/${data}`,
    method: 'delete',
  })
}

//根据字典项id获取字典项信息
const getDictsItemsById = data => {
  return service({
    url: `${UPMS_PREFIX}${DICT_PREFIX}/items/${data}`,
    method: 'get',
  })
}

//通过字典类型查找字典项
const getDictsItemByType = data => {
  return service({
    url: `${UPMS_PREFIX}${DICT_PREFIX}/type/${data}`,
    method: 'get',
  })
}

//修改字典项状态
const updateDictsItemStatus = data => {
  return service({
    url: `${UPMS_PREFIX}${DICT_PREFIX}/items/status`,
    method: 'put',
    header: 'application/x-www-form-urlencoded',
    data
  })
}

export {
  getDictsPage,
  insertDicts,
  updateDicts,
  deleteDicts,
  getDictsById,
  getDictsItemsPage,
  insertDictsItems,
  updateDictsItems,
  deleteDictsItems,
  getDictsItemsById,
  getDictsItemByType,
  updateDictsItemStatus,
  updateDictsStatus
}

/* ----------------- 字典管理模块end ---------------------- */

/* ----------------- 会话管理模块start ---------------------- */

//会话管理接口前缀
const TOKEN_PREFIX = '/tokens'

//分页获取token信息
const getTokensPage = data => {
  return service({
    url: `${UPMS_PREFIX}${TOKEN_PREFIX}/page`,
    method: 'get',
    data
  })
}

//注销用户token
const deleteTokens = data => {
  return service({
    url: `${UPMS_PREFIX}${TOKEN_PREFIX}/${data}`,
    method: 'delete',
  })
}

export {
  getTokensPage,
  deleteTokens
}

/* ----------------- 会话管理模块end ---------------------- */

/* ----------------- 日志管理模块start ---------------------- */

//日志管理接口前缀
const LOG_PREFIX = '/logs'

//分页条件查询日志表
const getLogsPage = data => {
  return service({
    url: `${UPMS_PREFIX}${LOG_PREFIX}/page`,
    method: 'get',
    data
  })
}

//通过id查询日志表
const getLogsById = data => {
  return service({
    url: `${UPMS_PREFIX}${LOG_PREFIX}/${data}`,
    method: 'get',
  })
}

//通过id删除日志表
const deleteLogs = data => {
  return service({
    url: `${UPMS_PREFIX}${LOG_PREFIX}/${data}`,
    method: 'delete',
  })
}

export {
  getLogsPage,
  getLogsById,
  deleteLogs
}

/* ----------------- 日志管理模块end ---------------------- */

/* ----------------- 文件管理模块start ---------------------- */

//文件管理接口前缀
const FILE_PREFIX = '/files'

const HEADERS = {
  'Authorization': `bearer  ${localStorage.getItem('token')}`,
  'Authorization-x': `bearer  ${localStorage.getItem('token')}`,
}
const UPLOAD_URL = `${NOPREFIX}${UPMS_PREFIX}${FILE_PREFIX}`

//上传文件
const uploadFile = data => {
  return service({
    url: `${UPMS_PREFIX}${FILE_PREFIX}`,
    method: 'post',
    data
  })
}

//分页条件查询文件
const getFilesPage = data => {
  return service({
    url: `${UPMS_PREFIX}${FILE_PREFIX}/page`,
    method: 'get',
    data
  })
}

//获取文件
const getFiles = data => {
  window.open(`${UPMS_PREFIX}${FILE_PREFIX}/${data.bucket}/${data.fileName}`)
  /* return service({
    url: `${UPMS_PREFIX}${FILE_PREFIX}/${data.bucket}/${data.fileName}`,
    method: 'get',
  }) */
}

//通过id删除文件
const deleteFiles = data => {
  return service({
    url: `${UPMS_PREFIX}${FILE_PREFIX}/${data}`,
    method: 'delete',
  })
}

export {
  uploadFile,
  getFilesPage,
  getFiles,
  deleteFiles,
  HEADERS,
  UPLOAD_URL
}
/* ----------------- 文件管理模块end ---------------------- */

/* ----------------- 二维码打印参数模块start ---------------------- */

//二维码打印参数模块接口前缀
const QRS_PREFIX = '/qrs'

//新增二维码打印参数
const insertQrs = data => {
  return service({
    url: `${PTMS_PREFIX}${QRS_PREFIX}`,
    method: 'post',
    data
  })
}

//修改二维码打印参数
const updateQrs = data => {
  return service({
    url: `${PTMS_PREFIX}${QRS_PREFIX}`,
    method: 'put',
    data
  })
}

//分页条件查询二维码打印参数
const getQrsPages = data => {
  return service({
    url: `${PTMS_PREFIX}${QRS_PREFIX}/page`,
    method: 'get',
    data
  })
}

//通过id查询二维码打印参数
const getQrsById = data => {
  return service({
    url: `${PTMS_PREFIX}${QRS_PREFIX}/${data}`,
    method: 'get',
  })
}

//通过id删除二维码打印参数
const deleteQrsById = data => {
  return service({
    url: `${PTMS_PREFIX}${QRS_PREFIX}/${data}`,
    method: 'delete',
  })
}

//修改二维码打印参数状态
const updateQrsStatus = data => {
  return service({
    url: `${PTMS_PREFIX}${QRS_PREFIX}/status`,
    method: 'put',
    header: 'application/x-www-form-urlencoded',
    data
  })
}

export {
  insertQrs,
  updateQrs,
  getQrsPages,
  getQrsById,
  deleteQrsById,
  updateQrsStatus
}

/* ----------------- 二维码打印参数模块end ---------------------- */

/* ----------------- 产品分类模块start ---------------------- */

//产品分类模块接口前缀
const PRODCATE_PREFIX = '/prods/categories'

//新增产品分类
const insertProdsCate = data => {
  return service({
    url: `${PTMS_PREFIX}${PRODCATE_PREFIX}`,
    method: 'post',
    data
  })
}

//修改产品分类
const updateProdsCate = data => {
  return service({
    url: `${PTMS_PREFIX}${PRODCATE_PREFIX}`,
    method: 'put',
    data
  })
}

//获得分类树结构
const getProdsCateTree = () => {
  return service({
    url: `${PTMS_PREFIX}${PRODCATE_PREFIX}/tree`,
    method: 'GET',
  })
}

//通过id查询产品分类
const getProdsCateById = data => {
  return service({
    url: `${PTMS_PREFIX}${PRODCATE_PREFIX}/${data}`,
    method: 'get',
  })
}

//通过id删除产品分类
const deleteProdsCateById = data => {
  return service({
    url: `${PTMS_PREFIX}${PRODCATE_PREFIX}/${data}`,
    method: 'delete',
  })
}

//修改产品分类状态
const updateProdsCateStatus = data => {
  return service({
    url: `${PTMS_PREFIX}${PRODCATE_PREFIX}/status`,
    method: 'put',
    header: 'application/x-www-form-urlencoded',
    data
  })
}

export {
  insertProdsCate,
  updateProdsCate,
  getProdsCateTree,
  getProdsCateById,
  deleteProdsCateById,
  updateProdsCateStatus
}

/* ----------------- 产品分类模块end ---------------------- */

/* ----------------- 产品品牌模块start ---------------------- */

//产品品牌模块接口前缀
const PRODBRANDS_PREFIX = '/prods/brands'

//新增产品品牌
const insertProdsBrands = data => {
  return service({
    url: `${PTMS_PREFIX}${PRODBRANDS_PREFIX}`,
    method: 'post',
    data
  })
}

//修改产品品牌
const updateProdsBrands = data => {
  return service({
    url: `${PTMS_PREFIX}${PRODBRANDS_PREFIX}`,
    method: 'put',
    data
  })
}


//修改产品品牌状态
const updateProdsBrandStatus = data => {
  return service({
    url: `${PTMS_PREFIX}${PRODBRANDS_PREFIX}/status`,
    method: 'put',
    header: 'application/x-www-form-urlencoded',
    data
  })
}

//分页条件查询产品品牌
const getProdsBrandsPage = data => {
  return service({
    url: `${PTMS_PREFIX}${PRODBRANDS_PREFIX}/page`,
    method: 'get',
    data
  })
}

//通过id查询产品品牌
const getProdsBrandsById = data => {
  return service({
    url: `${PTMS_PREFIX}${PRODBRANDS_PREFIX}/${data}`,
    method: 'get',
  })
}

//通过id删除产品品牌
const deleteProdsBrandsById = data => {
  return service({
    url: `${PTMS_PREFIX}${PRODBRANDS_PREFIX}/${data}`,
    method: 'delete',
  })
}

export {
  insertProdsBrands,
  updateProdsBrands,
  getProdsBrandsPage,
  getProdsBrandsById,
  deleteProdsBrandsById,
  updateProdsBrandStatus
}

/* ----------------- 产品品牌模块end ---------------------- */

/* ----------------- 产品模块start ---------------------- */

//产品模块接口前缀
const PRODS_PREFIX = '/prods'

//新增产品
const insertProds = data => {
  return service({
    url: `${PTMS_PREFIX}${PRODS_PREFIX}`,
    method: 'post',
    data
  })
}

//修改产品
const updateProds = data => {
  return service({
    url: `${PTMS_PREFIX}${PRODS_PREFIX}`,
    method: 'put',
    data
  })
}

//修改产品状态
const updateProdsStatus = data => {
  return service({
    url: `${PTMS_PREFIX}${PRODS_PREFIX}/status`,
    method: 'put',
    header: 'application/x-www-form-urlencoded',
    data
  })
}

//分页条件查询产品
const getProdsPage = data => {
  return service({
    url: `${PTMS_PREFIX}${PRODS_PREFIX}/page`,
    method: 'get',
    data
  })
}

//通过id查询产品
const getProdsById = data => {
  return service({
    url: `${PTMS_PREFIX}${PRODS_PREFIX}/${data}`,
    method: 'get',
  })
}

//通过id查询产品
const deleteProdsById = data => {
  return service({
    url: `${PTMS_PREFIX}${PRODS_PREFIX}/${data}`,
    method: 'delete',
  })
}

export {
  insertProds,
  updateProds,
  getProdsPage,
  getProdsById,
  deleteProdsById,
  updateProdsStatus
}

/* ----------------- 产品模块end ---------------------- */

/* ----------------- 产品规格属性模块start ---------------------- */

//产品规格属性模块接口前缀
const PRODATTRS_PREFIX = '/prods/attrs'

//新增产品规格属性
const insertProdsAttrs = data => {
  return service({
    url: `${PTMS_PREFIX}${PRODATTRS_PREFIX}`,
    method: 'post',
    data
  })
}

//修改产品规格属性
const updateProdsAttrs = data => {
  return service({
    url: `${PTMS_PREFIX}${PRODATTRS_PREFIX}`,
    method: 'put',
    data
  })
}

//获得规格树结构
const getProdsAttrsTree = data => {
  return service({
    url: `${PTMS_PREFIX}${PRODATTRS_PREFIX}/tree`,
    method: 'get',
  })
}

//分页条件查询产品规格属性
const getProdsAttrsPage = data => {
  return service({
    url: `${PTMS_PREFIX}${PRODATTRS_PREFIX}/page`,
    method: 'get',
    data
  })
}

//修改产品规格状态
const updateProdsAttrsStatus = data => {
  return service({
    url: `${PTMS_PREFIX}${PRODATTRS_PREFIX}/status`,
    method: 'put',
    header: 'application/x-www-form-urlencoded',
    data
  })
}

//新增产品规格值
const insertProdsAttrsVlues = data => {
  return service({
    url: `${PTMS_PREFIX}${PRODATTRS_PREFIX}/values`,
    method: 'post',
    data
  })
}

//修改产品规格值
const updateProdsAttrsVlues = data => {
  return service({
    url: `${PTMS_PREFIX}${PRODATTRS_PREFIX}/values`,
    method: 'put',
    data
  })
}

//修改产品规格值状态
const updateProdsAttrsVS = data => {
  return service({
    url: `${PTMS_PREFIX}${PRODATTRS_PREFIX}/values/status`,
    method: 'put',
    header: 'application/x-www-form-urlencoded',
    data
  })
}

//通过id查询产品规格属性
const getProdsAttrsById = data => {
  return service({
    url: `${PTMS_PREFIX}${PRODATTRS_PREFIX}/${data}`,
    method: 'get',
  })
}

//通过id查询产品规格值
const getProdsAttrsValuesById = data => {
  return service({
    url: `${PTMS_PREFIX}${PRODATTRS_PREFIX}/values/${data}`,
    method: 'get',
  })
}


export {
  insertProdsAttrs,
  updateProdsAttrs,
  getProdsAttrsTree,
  getProdsAttrsPage,
  updateProdsAttrsStatus,
  insertProdsAttrsVlues,
  updateProdsAttrsVlues,
  updateProdsAttrsVS,
  getProdsAttrsById,
  getProdsAttrsValuesById
}

/* ----------------- 产品规格属性模块end ---------------------- */


/* ----------------- 自定义流程字段模板模块start ---------------------- */

//自定义流程字段模板模块接口前缀
const FIELDS_PREFIX = '/fields'

//新增自定义流程字段模板
const insertFields = data => {
  return service({
    url: `${PTMS_PREFIX}${FIELDS_PREFIX}`,
    method: 'post',
    data
  })
}

//修改自定义流程字段模板
const updateFields = data => {
  return service({
    url: `${PTMS_PREFIX}${FIELDS_PREFIX}`,
    method: 'put',
    data
  })
}

//修改状态
const updateFieldStatus = data => {
  return service({
    url: `${PTMS_PREFIX}${FIELDS_PREFIX}/status`,
    method: 'put',
    header: 'application/x-www-form-urlencoded',
    data
  })
}

//分页条件查询自定义流程字段模板
const getFieldsPage = data => {
  return service({
    url: `${PTMS_PREFIX}${FIELDS_PREFIX}/page`,
    method: 'get',
    data
  })
}

//通过id查询自定义流程字段模板
const getFieldsById = data => {
  return service({
    url: `${PTMS_PREFIX}${FIELDS_PREFIX}/${data}`,
    method: 'get',
  })
}

//通过id查询自定义流程字段模板
const deleteFieldsById = data => {
  return service({
    url: `${PTMS_PREFIX}${FIELDS_PREFIX}/${data}`,
    method: 'delete',
  })
}

export {
  insertFields,
  updateFields,
  updateFieldStatus,
  getFieldsPage,
  getFieldsById,
  deleteFieldsById
}

/* ----------------- 自定义流程字段模板模块end ---------------------- */

/* ----------------- 行业类型模块start ---------------------- */

//行业类型模块接口前缀
const BUSINESS_PREFIX = '/business'

//新增行业类型
const insertBusiness = data => {
  return service({
    url: `${PTMS_PREFIX}${BUSINESS_PREFIX}`,
    method: 'post',
    data
  })
}

//修改行业类型
const updateBusiness = data => {
  return service({
    url: `${PTMS_PREFIX}${BUSINESS_PREFIX}`,
    method: 'put',
    data
  })
}

//分页条件查询行业类型
const getBusinessPage = data => {
  return service({
    url: `${PTMS_PREFIX}${BUSINESS_PREFIX}/page`,
    method: 'get',
    data
  })
}

//通过id查询行业类型
const getBusinessById = data => {
  return service({
    url: `${PTMS_PREFIX}${BUSINESS_PREFIX}/${data}`,
    method: 'get',
  })
}

//通过id删除行业类型
const deleteBusinessById = data => {
  return service({
    url: `${PTMS_PREFIX}${BUSINESS_PREFIX}/${data}`,
    method: 'delete',
  })
}

//修改状态
const updateBusinessStatus = data => {
  return service({
    url: `${PTMS_PREFIX}${BUSINESS_PREFIX}/status`,
    method: 'put',
    header: 'application/x-www-form-urlencoded',
    data
  })
}

export {
  insertBusiness,
  updateBusiness,
  getBusinessPage,
  getBusinessById,
  deleteBusinessById,
  updateBusinessStatus
}

/* ----------------- 行业类型模块end ---------------------- */

/* ----------------- 追溯流程模块start ---------------------- */

//追溯流程模块接口前缀
const PROCESS_PREFIX = '/process'

//新增追溯流程
const insertProcess = data => {
  return service({
    url: `${PTMS_PREFIX}${PROCESS_PREFIX}`,
    method: 'post',
    data
  })
}

//修改追溯流程
const updateProcess = data => {
  return service({
    url: `${PTMS_PREFIX}${PROCESS_PREFIX}`,
    method: 'put',
    data
  })
}

//分页条件查询一级追溯流程，包含了子流程树
const getProcessPage = data => {
  return service({
    url: `${PTMS_PREFIX}${PROCESS_PREFIX}/page`,
    method: 'get',
    data
  })
}

//查询子级流程
const getProcessChild = data => {
  return service({
    url: `${PTMS_PREFIX}${PROCESS_PREFIX}/child/${data}`,
    method: 'get',
  })
}

//通过id查询追溯流程
const getProcessById = data => {
  return service({
    url: `${PTMS_PREFIX}${PROCESS_PREFIX}/${data}`,
    method: 'get',
  })
}

//通过id删除追溯流程
const deleteProcessById = data => {
  return service({
    url: `${PTMS_PREFIX}${PROCESS_PREFIX}/${data}`,
    method: 'delete',
  })
}

//修改状态
const updateProcessStatus = data => {
  return service({
    url: `${PTMS_PREFIX}${PROCESS_PREFIX}/status`,
    method: 'put',
    header: 'application/x-www-form-urlencoded',
    data
  })
}

export {
  insertProcess,
  updateProcess,
  getProcessPage,
  getProcessChild,
  getProcessById,
  deleteProcessById,
  updateProcessStatus
}

/* ----------------- 追溯流程模块end ---------------------- */

/* ----------------- 产品追溯模块start ---------------------- */

const getTraceInfo = data => {
  const url = `${NOPREFIX}/valle/ptms/trace/` + data;
  return new Promise(function (resolve, reject) {
    //原生构造ajax请求
    let xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        resolve(this.response)
      }
    }
    xhr.open("get", url);
    xhr.send();
  }).then((res) => {
      return res
    },
    (err) => {
      console.log(err);
      return {
        code: 1,
        data: '获取信息失败'
      }
    });
}
export {
  getTraceInfo
}

/* ----------------- 产品追溯模块end ---------------------- */

/* ----------------- 配置项start ---------------------- */

const IMG_PREFIX = "";

export {
  IMG_PREFIX
}
/* ----------------- 配置项end ---------------------- */