import axios from 'axios'
import { Message, Loading } from 'element-ui'
import router from '../../router';

let baseURL = '';
if (process.env.NODE_ENV === 'development'){
    baseURL = '/api_target'
}else{
    baseURL = '';
}
let serverLoading
/** **** 创建axios实例 ******/
const service = axios.create({
  baseURL: baseURL, // api的base_url
  timeout: 60000 // 请求超时时间
})
/** **** request拦截器==>对请求参数做处理 ******/
service.interceptors.request.use(config => {
  serverLoading = Loading.service({
    text: '加载数据中....',
    lock: true,
    spinner: 'el-icon-loading',
    background: 'rgba(255, 255, 255, 0.7)',
    fullscreen: true
  })
  if(!config.noToken){
      config.headers = {
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': `bearer  ${localStorage.getItem('token')}`,
        'Authorization-x': `bearer  ${localStorage.getItem('token')}`,
    }
  }else{
    config.headers = {
      'Content-Type': 'application/json;charset=UTF-8'
  }
  }
  
  if(config.header){
     config.headers["Content-Type"] = config.header
  }
  (config.method === 'post' || config.method === 'put') && !config.header
    ? config.data = JSON.stringify({
      ...config.data
    })
    : config.params = {
      ...config.data
    }
  return config
}, error => { // 请求错误处理
  serverLoading.close()
  console.log(error, 'error')
  Message.error({
    message: '服务器开小差啦~'
  })
})
/** **** respone拦截器==>对响应做处理 ******/
service.interceptors.response.use(
  response => { // 成功请求到数据
    serverLoading.close()
    // 这里根据后端提供的数据进行对应的处理
    return Promise.resolve(response.data)
  },
  error => { // 响应错误处理
    if (error.response.data.code === 1) {
      setTimeout(() => {
        serverLoading.close()
        if(error.response.status===401){
             Message.error({
                message: "登录过期"
            })
            localStorage.clear()
            //location.reload()
            router.push({path:"/login"})
        }else{
            Message.error({
                message: error.response.data.message
            })
        }
      }, 1500)
      
    } else {
      serverLoading.close()
      Message.error({
        message: '请求超时'
      })
      return Promise.reject(error)
    }
  }
)
export default service
